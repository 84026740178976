import React from 'react';
import Container from 'react-bootstrap/Container';
import TextTransition, { presets } from 'react-text-transition';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const TEXTS = ['Forest', 'Building', 'Tree', 'Color'];
const App = () => {
    const [index, setIndex] = React.useState(0);
  
    React.useEffect(() => {
      const intervalId = setInterval(
        () => setIndex((index) => index + 1),
        3000, // every 3 seconds
      );
      return () => clearTimeout(intervalId);
    }, []);
  
    return (
      <h1>
        <TextTransition springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition>
      </h1>
    );
  };

function Header() {

    return (
        <>
            <Container>
                <div className='banner text-center'>
                    <div className='banner-head'>
                        Hey Folks, I’m Vishal<span>.</span>
                    </div>
                    <div className='banner-sub'>
                        a web designer
                    </div>
                </div>
            </Container >
        </>
    );
}

export default Header;