import './Styles/App.css';
import Header from "../src/Components/Header";
import Footer from "../src/Components/Footer";
import Banner from "../src/Components/Banner";

function App() {
  return (
    <>
      <Header />
      <Banner />
      <Footer />
    </>
  );
}

export default App;
